import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '',
      component: () => import(/* webpackChunkName: "Home" */ './Dash.vue'),
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue')
        },
        {
          path: '/produtos/:produto_id',
          name: 'Item',
          component: () => import(/* webpackChunkName: "Produtos" */ './views/home/Home.vue')
        },
        {
          path: '/produtos',
          name: 'produtos',
          component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue')
        },
        {
          path: '/cidades',
          name: 'cidades',
          component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue')
        },
        {
          path: '/onde_estamos',
          name: 'onde_estamos',
          component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue')
        },
        {
          path: '/empresa',
          name: 'empresa',
          component: () => import(/* webpackChunkName: "Empresa" */ './views/home/Home.vue')
        },
        {
          path: '/sobre',
          name: 'sobre',
          component: () => import(/* webpackChunkName: "Empresa" */ './views/empresa/Empresa.vue')
        },
        { path: '/:pathMatch(.*)*',
          name: 'not-found',
          component: () => import(/* webpackChunkName: "404" */ './views/NotFound/NotFound.vue')
        },
      ]
    },
  ]
})

export default router
