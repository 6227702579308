import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'

import './assets/css/main.scss'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import 'aos/dist/aos.css'
import {sync} from 'vuex-router-sync'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {createVuetify} from 'vuetify'
import titulo from './components/titulo'
import Parallax from './components/Parallax'
import ModalCadastro from './components/ModalCadastro'

import VueGoogleMaps from '@fawmi/vue-google-maps'


const vuetify = createVuetify({
  components,
  directives,
})


const app = createApp(App)
sync(store, router)
app.use(router)
app.use(vuetify)

app.component('pro-titulo', titulo)
app.component('parallax', Parallax)
app.component('pro-modal', ModalCadastro)

// A key precisará ser alterada depois
app.use(VueGoogleMaps, {
  load: {
    v: '3.51',
    key: 'AIzaSyAb0wzUYfv-8mxhDsIS8nv7ddodU-Df5Eo',
    libraries: 'visualization'

  }
})

app.mount('#app')
