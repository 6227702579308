<template>
  <div class="prllx">
    <div :id="id" class="bgParallax" data-speed="15">
      <article>
        <slot></slot>
      </article>
    </div>
  </div>
</template>

<script async>
/* eslint-disable */
  export default {
    name: "Parallax",
    props: ['id'],
    mounted() {
    }
  }
</script>

