<template>
  <div>
    <button class="open-button" @click="IrParaUrl('https://api.whatsapp.com/send?phone=5533999441140')">
      <img src="../assets/img/whats.svg" alt="">
    </button>
  </div>
</template>

<script async>
/* eslint-disable */
import {mapGetters} from "vuex";
  export default {
    name: "PopupWhats",
    data: () => ({
      contato: [],
    }),
    methods: {
      IrParaUrl(path, target='_blank'){
        var a = document.createElement('a')
        a.href = path
        a.target = target
        a.click()
      },
    },
    computed: {
      ...mapGetters('empresa', {empresa: 'pegaEmpresa', filtro: 'pegaFiltro'}),
    }
  }
</script>

<style scoped>
  * {
    box-sizing: border-box;
  }

  /* Button used to open the chat form - fixed at the bottom of the page */
  .open-button {
    background-color: transparent;
    color: white;
    padding: 20px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
    z-index: 900;
  }

  .open-button img {
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 50px;
    height: 50px;
  }
</style>
