/* eslint-disable */
const state = {
  dialogError: false,
  objMsgError: null,
  snackSuccess: false,
  msgSuccess: '',
};

const getters = {
};

const actions = {
  toggleDialogError ({ commit }) {
    commit('SET_DIALOGERROR', !state.dialogError)
  },
  setObjMsgError ({ commit }, valor) {
    commit('SET_OBJMSGERROR', valor);
    commit('SET_DIALOGERROR', true)
  },
  toggleSnackSuccess ({ commit }) {
    commit('SET_SNACKSUCCESS', !state.snackSuccess)
  },
  setMsgSuccess ({ commit }, valor) {
    commit('SET_MSGSUCCESS', valor);
    commit('SET_SNACKSUCCESS', true)
  },
};

const mutations = {
  SET_DIALOGERROR (state, payload) {
    state.dialogError = payload
  },
  SET_OBJMSGERROR (state, payload) {
    state.objMsgError = payload
  },
  SET_SNACKSUCCESS (state, payload) {
    state.snackSuccess = payload
  },
  SET_MSGSUCCESS (state, payload) {
    state.msgSuccess = payload
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
