<template>
  <div>
    <h2 :class="classe" style="font-weight: bold">Site Seguro</h2>
    <br>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="6" md="6" v-for="(img,index) in imagens" :key="'icones'+index" class="proboximagem">
        <v-card @click="IrParaUrl(img.link)" style="background: white; width: 100%; height: 80px;"
                class="proboximagemCard">
          <v-img :src="img.src" style="height: 100%;" contain></v-img>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
  import img_4 from './ssl.webp'
  import img_5 from './google_security.webp'

  export default {
    name: 'SiteSeguro',
    props: {
      url: {default: ''},
      classe: {default: ''},
    },
    data: () => ({
      imagens: [
        {
          ico_id: 2,
          src: img_4,
          link: 'https://www.sslshopper.com/ssl-checker.html#hostname=',
          nome: 'ssl'
        },
        {
          ico_id: 2,
          src: img_5,
          link: 'https://transparencyreport.google.com/safe-browsing/search?url=F&hl=pt-PT',
          nome: 'google_security'
        },
      ]
    }),
    methods: {
      IrParaUrl(path, target = '_blank') {
        var a = document.createElement('a')
        a.href = path
        a.target = target
        a.click()
      },
    },
    mounted() {
      this.imagens = [
        {
          ico_id: 2,
          src: img_4,
          link: 'https://www.sslshopper.com/ssl-checker.html#hostname=' + this.url,
          nome: 'ssl'
        },
        {
          ico_id: 2,
          src: img_5,
          link: 'https://transparencyreport.google.com/safe-browsing/search?url=' + this.url + '&hl=pt-PT',
          nome: 'google_security'
        },
      ]
    },
  }
</script>
<style scoped>
  .proboximagemCard {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: transform 0.2s linear;
    -moz-transition: transform 0.2s linear;
    -o-transition: transform 0.2s linear;
    transition: transform 0.2s linear !important;
  }

  .proboximagem:hover .proboximagemCard {
    -webkit-transform: scale(1.1);
    transform: scale(1.1) !important;
    border: 1px solid red;
  }
</style>
