/* eslint-disable */
import {createStore} from 'vuex'
import geral from './geral'



export default createStore({
  modules: {
    geral,
  }
})
